import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { DEFAULT_PERSONAS } from "./Persona";
import { getTokenOrRefresh } from "../utils";
import ChatStatus from "./ChatStatus";
import ChatInput from "./ChatInput";
// import '../assets/styles/ChatDetails.css';
import ChatOutput from "./ChatOutput";
import ChatHistory from "./ChatHistory";
import { MessageResponse } from "../models/Simulation";

const CHAT_OUTPUT = "o";
const CHAT_INPUT = "i";

function ChatDetails({ simulation, chat, personaId }) {
  const location = useLocation();
  const [persona, setPersona] = useState();
  const [error, setError] = useState(false);
  const [azureToken, setAzureToken] = useState(false);
  const [messages, setMessages] = useState(chat.messages);
  const [nextStage, setNextStage] = useState(CHAT_INPUT);
  const [userMessage, setUserMessage] = useState("");
  const [botMessage, setBotMessage] = useState("");
  const historyContainerRef = useRef(null); //used for auto-scroll
  const simId = localStorage.getItem("sim_id");
  const chatId = localStorage.getItem("chat_id");

  const chatInputURL = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${
    simulation.simulationId || simId
  }/${chat.chatId || chatId}/msg`;

  const appendMessage = (sender, content) => {
    const newMessage = new MessageResponse(sender, content, new Date());
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  useEffect(() => {
    const setup = async () => {
      setPersona({
        ...DEFAULT_PERSONAS.find((p) => p.id === personaId),
        id: personaId,
      });
      console.log("persona and chat session initiated successfully!");

      if (!azureToken) {
        const tokenObj = await azureRecorderSetup();
        console.log("azure token is set!", tokenObj);
        setAzureToken(tokenObj);
      }
    };

    setup();
  }, [azureToken]);

  const azureRecorderSetup = async () => {
    let tokenObj = await getTokenOrRefresh();
    if (tokenObj.error) {
      setError(true);
      throw new Error("Failed in setting up this chat!");
    }
    return tokenObj;
  };

  function chatInputComplete(user_message, bot_message) {
    appendMessage("U", user_message);
    console.log(
      "chat input is complete...now show the output with message..",
      bot_message
    );
    setNextStage(CHAT_OUTPUT);
    setBotMessage(bot_message);
  }

  function chatOutputComplete(bot_message) {
    appendMessage("B", bot_message);
    console.log("chat output is now complete..", bot_message);
    setNextStage(CHAT_INPUT);
    setBotMessage("");
  }

  useEffect(() => {
    // This effect will be triggered whenever nextStage or botMessage changes
    console.log("Component should re-render now", nextStage, botMessage);
  }, [nextStage, botMessage]);

  //This method helps in scrolling to the bottom of the div
  const setHistoryContainerRef = (element) => {
    if (element) {
      historyContainerRef.current = element;
      historyContainerRef.current.scrollTop =
        historyContainerRef.current.scrollHeight;
    }
  };

  return (
    <div className="container h-[80vh] overflow-hidden flex flex-col">
      {simulation && azureToken.authToken && (
        <>
          <div className="grid grid-cols-1 gap-4">
            {/* <div>
                <Persona person={persona} />
            </div> */}
            <div>
              <ChatStatus simulation={simulation} chat={chat} />
            </div>
          </div>
          <div
            ref={setHistoryContainerRef}
            className="flex-1 overflow-y-auto flex flex-col"
          >
            <div>
              <ChatHistory messages={messages} />
              <div></div>
              <div>
                {nextStage === CHAT_OUTPUT && (
                  <ChatOutput
                    simulation={simulation}
                    azureToken={azureToken}
                    message={botMessage}
                    onComplete={chatOutputComplete}
                  />
                )}
              </div>
            </div>
            <div className="sticky bottom-0 bg-gray-100 p-4 rounded-lg">
              <div className="max-w-md mx-auto">
                {nextStage === CHAT_INPUT && (
                  <ChatInput
                    url={chatInputURL}
                    azureToken={azureToken}
                    onComplete={chatInputComplete}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {!location.state && !azureToken.authToken && (
        <div>Unable to start this chat! Redirecting in 5 seconds...</div>
      )}
    </div>
  );
}

export default ChatDetails;
