// components/LoginForm.jsx
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const HOST = process.env.REACT_APP_SIM_BACKEND_HOST;

  const isValidEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    // Simple password validation (at least 8 characters, one uppercase, one lowercase, one number)
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setError("Invalid email address");
    }
    // else if (!isValidPassword(password)) {
    //   setError('Password must be at least 8 characters long, contain one uppercase, one lowercase, and one number');
    // }

    // Make a POST request to the FastAPI backend to get the access token
    const url = `${HOST}/auth/token`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `username=${email}&password=${password}`,
    });

    console.log(response);
    const data = await response.json();
    if (response.ok) {
      // Store the access token and refresh token
      localStorage.setItem("accessToken", data.access_token);
      //   localStorage.setItem('refreshToken', data.refresh_token);
      // Redirect to the desired page or do something else with the tokens
      navigate("/sim");
    } else {
      // Handle error
      setError("Login failed! Please try again.");
      console.error("Login failed:", data.error);
    }
  };

  return (
    <div className="flex items-center justify-center">
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded shadow-md">
        <h2 className="text-2xl font-bold mb-6">Login</h2>
        <div className="mb-4">
          <label htmlFor="useremail" className="block font-bold mb-2">
            Email
          </label>
          <input
            type="text"
            id="username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block font-bold mb-2">
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded"
          />
        </div>
        {error && <div className="mb-4 text-red-500 font-bold">{error}</div>}
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
