import React from 'react';
// import HeroSection from '../components/HeroSection';
// import FeaturedProducts from '../components/FeaturedProducts';
import SimulationGrid from '../components/SimulationGrid';

const SimulationHome = () => {
  console.log("in simulation home")
  return (
    <div>
      <SimulationGrid />
    </div>
  );
};

export default SimulationHome;