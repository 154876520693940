import React, { useState } from "react";
import "../assets/styles/ChatStatus.css"; // Assuming the CSS is in Chat.css
import ChatClose from "./ChatClose";
import Persona from "./Persona";

function ChatStatus({ simulation, chat }) {
  const [isBardPopoverOpen, setIsBardPopoverOpen] = useState(false);
  const toggleBardPopover = () => setIsBardPopoverOpen(!isBardPopoverOpen);

  const bardInfo = (
    <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
  <div class="w-full max-w-2xl max-h-[90vh] overflow-y-auto bg-white rounded-lg shadow-xl">
    <div class="sticky top-0 bg-white z-10 border-b p-4">
      <div class="flex justify-between items-center">
        <h2 class="text-xl font-bold text-gray-800">About the Role Play</h2>
        <button onClick={toggleBardPopover} class="text-gray-500 hover:text-gray-700 focus:outline-none" aria-label="Close">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
    <div class="p-4 space-y-4">
      <p class="text-gray-700">
        In this role play, you'll practice as a health coach having their first session with a client aiming to get healthier and lose weight. The client may stray off topic, so you'll need to use appropriate coaching techniques while empathetically guiding them through 4 stages and achieving the sub-goals within each.
      </p>
      <p class="italic text-gray-600 border-l-4 border-gray-300 pl-4">
        {simulation.personaDetails}
      </p>
      <div>
        <h3 class="text-lg font-semibold text-gray-800 mb-2">Stages and Goals</h3>
        <div class="space-y-2">
          <div class="bg-indigo-50 border border-indigo-200 rounded-lg p-3">
            <h4 class="text-sm font-semibold text-indigo-700 mb-1">1: Establish rapport</h4>
            <ul class="list-disc pl-5 space-y-0.5 text-xs text-gray-700">
              <li>Demonstrate presence through reflections</li>
              <li>Show interest with open questions</li>
              <li>Encourage with affirmations</li>
              <li>Get patient to share health challenges</li>
            </ul>
          </div>
          <div class="bg-purple-50 border border-purple-200 rounded-lg p-3">
            <h4 class="text-sm font-semibold text-purple-700 mb-1">2: Visualize better future</h4>
            <ul class="list-disc pl-5 space-y-0.5 text-xs text-gray-700">
              <li>Discuss ideal future health state</li>
              <li>Help overcome visualization impediments</li>
            </ul>
          </div>
          <div class="bg-blue-50 border border-blue-200 rounded-lg p-3">
            <h4 class="text-sm font-semibold text-blue-700 mb-1">3: Explain coaching process</h4>
            <ul class="list-disc pl-5 space-y-0.5 text-xs text-gray-700">
              <li>Describe behavior change philosophy</li>
              <li>Emphasize client's self-efficacy</li>
              <li>Outline interaction frequency</li>
              <li>Explain SMART goal setting</li>
            </ul>
          </div>
          <div class="bg-green-50 border border-green-200 rounded-lg p-3">
            <h4 class="text-sm font-semibold text-green-700 mb-1">4: Set goals</h4>
            <ul class="list-disc pl-5 space-y-0.5 text-xs text-gray-700">
              <li>Redirect towards goal setting</li>
              <li>Propose three distinct goals</li>
              <li>Get commitment to one goal</li>
              <li>Frame goal in SMART format</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  );

  return (
    <div className="flex flex-col bg-gray-100 w-full h-full overflow-hidden rounded-lg shadow-md">
      <div className="flex flex-row justify-between bg-white p-4 rounded-t-lg">
        <h2 className="text-xl font-bold text-gray-800">
          Instructions and Context
          <button
            type="button"
            className="text-gray-400 hover:text-blue-500 focus:outline-none ml-2"
            onClick={toggleBardPopover}
          >
            <span className="text-gray-400 hover:text-blue-500 focus:outline-none">
              (i)
            </span>
          </button>
        </h2>
        {/* <button className={` ${chat.status === 'ACTIVE' ? "text-white bg-green-500 hover:bg-green-700 focus:outline-none px-2 py-1 rounded-full"
        : "text-white bg-red-500 hover:bg-red-700 focus:outline-none px-2 py-1 rounded-full" }`}>
          <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-6h-2v2h2V12z"
              clipRule="evenodd"
            />
          </svg>
        </button> */}
        <ChatClose chat={chat} />
        {isBardPopoverOpen && bardInfo}
      </div>
    </div>
  );
}

export default ChatStatus;
