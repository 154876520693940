import { useEffect, useState } from "react";
import TypingBubble from "./TypingBubble"

const speechsdk = require('microsoft-cognitiveservices-speech-sdk')

const ChatOutput = ({simulation, azureToken, message: bot_message, onComplete}) => {

    const [audioConfig, setAudioConfig] = useState();
    const [speechConfig, setSpeechConfig] = useState();
    const [isTyping, setIsTyping] = useState(bot_message === undefined || bot_message === "")
    // const [isTyping, setIsTyping] = useState(true)
    const [botTalking, setBotTalking] = useState(true)
    const [player, updatePlayer] = useState({p: undefined, muted: false});

    function generateSSML(simulation, textToSpeak){
        console.log("generating SSML for simulation -> ", simulation)
        if(simulation.personaId == 1){
            return `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="en-US">
            <voice name="en-US-DavisNeural">
              <mstts:express-as style="sad" styledegree="1.2" role="OlderAdultMale">
                <prosody volume="soft">
                  ${textToSpeak}
                </prosody>
              </mstts:express-as>
            </voice>
          </speak>`;
        } else if(simulation.personaId == 2){
            return `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="en-US">
            <voice name="en-US-JennyNeural">
              <mstts:express-as style="hopeful" styledegree="1.2">
                <prosody volume="soft">
                  ${textToSpeak}
                </prosody>
              </mstts:express-as>
            </voice>
          </speak>`;
        }
    }

    useEffect( () => {
        console.log('isTyping... --> ', isTyping, bot_message)
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(azureToken.authToken, azureToken.region);
        const myPlayer = new speechsdk.SpeakerAudioDestination();

        myPlayer.onAudioEnd = function(_){
            console.log("on audio end..")
            setBotTalking(false);
            onComplete(bot_message)
        }

        updatePlayer(p => {p.p = myPlayer; return p;});    
        const audioConfig = speechsdk.AudioConfig.fromSpeakerOutput(player.p);
        setAudioConfig(audioConfig)
        setSpeechConfig(speechConfig)        
    }, [])
    

    async function talk(){
        setIsTyping(true)
        setBotTalking(true)
        if(!speechConfig || !audioConfig){
            return
        }

        let synthesizer = new speechsdk.SpeechSynthesizer(speechConfig, audioConfig);
        console.log("istyping...", isTyping, bot_message)        
        if(isTyping || synthesizer === undefined){
            return
        }
        
        const textToSpeak = bot_message
        const ssml = generateSSML(simulation, textToSpeak)
        console.log(`speaking text: ${textToSpeak}... with player: ${player.p}`);
        
        synthesizer.speakSsmlAsync(ssml,
            result => {
                let text;
                if (result.reason === speechsdk.ResultReason.SynthesizingAudioCompleted) {
                    text = `synthesis finished for "${textToSpeak}".\n`
                } else if (result.reason === speechsdk.ResultReason.Canceled) {
                    text = `synthesis failed. Error detail: ${result.errorDetails}.\n`
                }
                synthesizer.close();
                synthesizer = undefined;
                console.log(text);                
            },
            function (err) {
                console.log(`Error: ${err}.\n`);

                synthesizer.close();
                synthesizer = undefined;
            }
        );
    }

    return (
        <div>
        {botTalking && (
        <div className="flex space-x-4 justify-center">
            {isTyping && 
                <TypingBubble />
            }
            {!isTyping &&
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={talk}>Next
                </button>
            }
        </div>
        )}</div>
    )
}

export default ChatOutput