import React from 'react';
// import '../assets/styles/Persona.css';  // Assuming the CSS is in Persona.css

function Persona({ person }) {
    console.log("person --> ", person)
    if (!person) return <div className="persona">Loading...</div>;

    return (
        <div className="persona">
            You are chatting with {person.name}. {person.name} is {person.age} years old and suffers from the following conditions: 
            condition 1, condition 2, condition 3.....
            {/* <h2 className="persona-name">{person.name}</h2>
            <p className="persona-detail"><strong>Age:</strong> {person.age}</p>
            <p className="persona-detail"><strong>Gender:</strong> {person.gender}</p>
            <p className="persona-detail"><strong>Likes:</strong> {person.likes.join(", ")}</p>
            <p className="persona-detail"><strong>Dislikes:</strong> {person.dislikes.join(", ")}</p>
            <p className="persona-detail"><strong>Family:</strong> {person.family}</p> */}
        </div>
    );
}

export const DEFAULT_PERSONAS = [
    { id: 'persona_1-John', name: 'John Doe', age: 30, gender: 'Male', likes: ['Reading', 'Gaming'], dislikes: ['Spicy Food'], family: 'Wife and 2 kids' },
    { id: '2', name: 'Jane Smith', age: 25, gender: 'Female', likes: ['Cooking', 'Running'], dislikes: ['Loud Noises'], family: 'Cat named Whiskers' }
];

export default Persona;