import axios from 'axios';
import Cookie from 'universal-cookie';

const HOST = process.env.REACT_APP_SIM_BACKEND_HOST

export async function getTokenOrRefresh() {
    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');
    const accessToken = localStorage.getItem("accessToken");

    if (speechToken === undefined) {
        try {
            const res =  await axios.post(`${HOST}/sim/refresh`, {
                headers: {
                    "Authorization": "Bearer "+accessToken,
                },
            });
            const token = res.data.az_token;
            const region = res.data.region;
            // console.log("response: ", res.data)
            cookie.set('speech-token', region + ':' + token, {maxAge: 540, path: '/'});

            console.log('Token fetched from back-end: ' + token);
            return { authToken: token, region: region };
        } catch (err) {
            console.log(err);
            return { authToken: null, error: err };
        }
    } else {
        console.log('Token fetched from cookie: ' + speechToken);
        const idx = speechToken.indexOf(':');
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    }
}

export async function sendBlobToBackend(url, blob, text){
    const accessToken = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('audio', blob);
    formData.append('text', text);
    try {
        const res = await axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": "Bearer "+accessToken
            },        
        });
        console.log('Response:', res.bot_response);
        return res.data.bot_response
    } catch (error) {
        console.error('Error:', error);
        // Handle the error as needed
    }
}

export async function fetchAuthenticatedUrl(url) {
    try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(
            url, {
                headers: new Headers({
                    "Authorization": "Bearer "+accessToken
                })
            },
        )
        if (!response.ok) {
            throw new Error('Failed to fetch simulation data');
        }
        const data = await response.json();
        return {
            "data": data,
            "error": undefined
        }
    } catch (error) {
        return {
            "data": undefined,
            "error": error
        }
    } 
}

export async function postURL(url, data={}) {
    try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await axios.post(url, data, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+accessToken
              },
        });        
        return {
            "data": response.data,
            "error": undefined
        }
    } catch (error) {
        return {
            "data": undefined,
            "error": error
        }
    } 
}

const keyValuePairs = {
    'uncover_patients_own_understanding_of_health': "Uncover patient's own understanding of their health",
    'clinician_has_clarified_end_of_life_prognosis': "Clarify end of life prognosis to the patient",
    'patient_agreed_to_create_plan': "Get the patient to agree to creating a future health plan",
    'importance_of_end_of_life_planning_discussed': "Emphasize the important of end of life planning",
    'prognosis_progression_scenarios': "Describe the prognosis, progression, and health scenarios at end of life",
    'incapacitaion_decision_making': "Describe how patient's may become incapacitated at end of life",
    'options_care_pain_management': "Describe options for comfort care and pain management",
    'decisions_healthcare_agent': "Describe decisions that a healthcare agent would make",
    'discovery_of_whats_important_in_end_of_life_care': "Discover what is important for the patient in end of life care",
    'discovery_of_past_experience_with_end_of_life': "Explore any past experience with others' end of life",
    'counseled_on_where_to_gather_information': "Counsel patient on where to gather information",
    'stated_next_step_is_five_wishes': "State that the next step is to document patient's 5 wishes",
    'explored_end_of_life_preference': "Explore what the patient's end of life preferences are",
    'explore_patients_goals_for_end_of_life': "Explore with the patient's goal for end of life care are",
    'summarized': "Summarize the conversation thus far",
    'identify_healthcare_agent': "Identify patient's healthcare agent",
    'decision_life_sustaining_treatment': "Document's patient preferences for what life sustaining treatment they'd want at end of life",
    'decision_comfort': "Document's patient preferences for pain management and comfort care",
    'loved_ones_behavior': "Document's patient preferences for how they want loved ones to behave around them",
    'wishes_for_loved_one': "Document's patient's wishes for their loved ones",
    'reflection_used_in_response': "Use a reflection to build rapport",
    'open_question_used_in_response': "Use open questions to learn more about the client",
    'affirmation_used_in_response': "Use affirmations to encourage the client",
    'client_shared_health_challenge': "Get the client to share their health challenge in detail",
    'try_to_get_client_to_define_ideal_health': "Ask the client to visualize what their ideal health outcome would look like",
    'client_shared_view_of_ideal_health': "Get the client to articulate what their ideal health would be",
    'describe_how_cindy_could_change_behavior': "Describe your philosophy for how Cindy could change behaviors",
    'emphasize_belief_in_clients_self_efficacy': "Emphasize your belief in the client's self efficacy",
    'outline_how_when_client_coach_engagement': "Outline how and when you will engage with the client",
    'describe_smart_goal_setting': "Describe SMART goal setting",
    'redirect_to_goal_setting': "Redirect the patient towards setting goals",
    'suggest_multiple_goals': "Suggest multiple goals to the patient",
    'articulate_goal_in_smart_format': "Articulate the client's preferred goal in a SMART format",
    'obtain_commitment_from_client': "Obtain commitment from the client on taking action on the goal"
  };

  

export function prettyPrintChecklist(input){
    const trimmedInput = input.trim().toLowerCase();
    for (const [key, value] of Object.entries(keyValuePairs)) {
      if (trimmedInput === key.toLowerCase()) {
        return value;
      }
    }
    return input;
}
