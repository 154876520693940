import React, { useState, useEffect, useRef } from "react";
import { sendBlobToBackend } from "../utils";
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { useAudioRecorder } from "react-audio-voice-recorder";
import TypingBubble from "./TypingBubble";

const speechsdk = require("microsoft-cognitiveservices-speech-sdk");

const ChatInput = ({ url, azureToken, onComplete }) => {
  const [blob, setBlob] = useState();
  const recorder = useAudioRecorder();
  const [isRecognizerReady, setRecognizerReady] = useState(false);
  const [recognizer, setRecognizer] = useState();
  
  const [transcript, setTranscript] = useState([]);
  const transcriptRef = useRef(transcript);

  const [showStopBtn, setShowStopBtn] = useState(false);
  const [userPressedStop, setUserPressedStop] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const [azRecordingFailed, setAzRecordingFailed] = useState(false)

  const [speechEndDetected, setSpeechEndDetected] = useState(false)

  useEffect(() => {
    if (!isRecognizerReady) {
      let currRecognizer = azureRecorderSetup(azureToken);
      setRecognizer(currRecognizer);
      setRecognizerReady(true);
      currRecognizer.recognized = (s, e) => processRecognizedTranscript(e);
      currRecognizer.speechStartDetected = (s, e) => {
        console.log("speech start detected");
        setSpeechEndDetected(false)
      };
      currRecognizer.speechEndDetected = (s, e) => {
        console.log("speech end detected")
        setSpeechEndDetected(true)
      }
      currRecognizer.recognizing = (s, e) => {
        console.log("speech recognizing")
      }
    }
  }, []);

  const processRecognizedTranscript = (event) => {
    if (event.result.reason === sdk.ResultReason.RecognizedSpeech) {
      const newTranscript = [ ...transcriptRef.current, event.result.text]
      setTranscript(newTranscript);
      transcriptRef.current = newTranscript
      console.log("offset and Transcript: -->", event.offset, event.result.text, transcriptRef.current);
    }
  };

  useEffect(() => {
    if (recorder.isRecording){
      return;
    } 
    console.log("setting blob");
    setBlob(recorder.recordingBlob);
    //let's not add the audio element temporarily
    // addAudioElement(recorder.recordingBlob)
  }, [recorder.recordingBlob]);

  useEffect(() => {
    if (!blob || transcriptRef.current.length == 0 || !userPressedStop || !speechEndDetected) {
      return;
    }
    console.log("sending data to the backend...", transcriptRef.current.length, blob, userPressedStop);
    setIsTyping(true);

    sendBlobToBackend(url, blob, transcript.join(". "))
      .then((bot_response) => {
        setIsTyping(false)
        setSpeechEndDetected(false)
        setTranscript([])
        setUserPressedStop(false)
        onComplete(transcript, bot_response);
      })
      .catch((error) => {
        console.error("Error sending data to backend:", error);
      });
  }, [transcript, blob, userPressedStop, speechEndDetected]);

  const azureRecorderSetup = (tokenObj) => {
    if (tokenObj.error) {
      throw new Error("Failed in setting up this chat!");
    }
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
    speechConfig.speechRecognitionLanguage = "en-US";
    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    return new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
  };

  //todo this should add the blob and text back
  const addAudioElement = (blob) => {
    console.log("adding audio element: ", blob);
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);
  };

  function startRecording() {
    setTranscript([]);
    setShowStopBtn(true);
    setUserPressedStop(false)
    recorder.startRecording();
    recognizer.startContinuousRecognitionAsync();
  }

  function stopRecording() {    
    recorder.stopRecording();
    recognizer.stopContinuousRecognitionAsync();

    //blob is made available after stop recording is called    
    setUserPressedStop(true)    
    // console.log(recorder.recordingBlob)
  }

  function hideError(){
    setAzRecordingFailed(false)
  }

  return (
    <div>
      {azRecordingFailed && (
        <div className="z-50">
          <div id="error-message" className=" text-red-500 text-sm font">
            Something is wrong. Try again or reach for help if issue persists!
            <button className="text-red-500 hover:text-red-700 focus:outline-none" onClick={hideError}>
            <svg width="20" height="20" viewbox="0 0 40 40"><path d="M 10,10 L 20,20 M 20,10 L 10,20" stroke="black" stroke-width="2" /></svg>
            </button>
          </div>
        </div>
        )
      }
      <div className="flex space-x-4 justify-center">
        {isTyping && (
          <div>
            <TypingBubble />
          </div>
        )}
        <div>
          {!isTyping && !showStopBtn && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={startRecording}
            >
              Start recording
            </button>
          )}
          {!isTyping && showStopBtn && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={stopRecording}
            >
              Stop recording
            </button>
          )}
        </div>
      </div>

      {/* <button onClick={recorder.stopRecording}>Stop recording</button>   */}
      {recorder.isRecording && (
        <div className="flex space-x-4 justify-center">
          <LiveAudioVisualizer
            mediaRecorder={recorder.mediaRecorder}
            width={200}
            height={75}
            barWidth={0.5}
            barColor={'#051226'}
          />
        </div>
      )}
      {/* {blob && (
        <AudioVisualizer
        blob={blob}
        width={500}
        height={75}
        barWidth={3}
        gap={2}
        barColor={'lightblue'}
        />
      )}  */}
    </div>
  );
};

export default ChatInput;
