import React from "react";
import useSSO from "./useSSO";
import { Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SimInstruction from "./SimInstructions";
const SSO = () => {
  const { isLoading, error } = useSSO();
  if (isLoading) {
    return <div>Loading..</div>;
  }
  if (error) {
    return (
      <div className="flex items-center text-gray-500">
        <ErrorOutlineIcon className=" mr-2" />
        <Typography variant="h6" className="text-red-400">
          The link you are trying to access is either invalid or expired
        </Typography>
      </div>
    );
  }
  return <SimInstruction />;
};

export default SSO;
