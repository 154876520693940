import { json } from "react-router-dom";

class MessageResponse {
  constructor(sender, content, time) {
    this.sender = sender;
    this.content = content;
    this.time = time;
  }
}

class ChatResponse {
  constructor(chatId, stage, status, addedOn, messages = [], stageHistory = {}) {

    this.chatId = chatId;
    this.stage = stage;
    this.status = status;
    this.addedOn = addedOn;
    this.messages = messages;
    this.stageHistory = stageHistory;
  }

  addMessages(messages) {
    this.messages.push(...messages);
  }
}

class SimResponse {
  constructor(simulationId, simDetails, personaId, personaDetails, userId, status, stage, addedOn, config, chatResponse = []) {
    this.simulationId = simulationId;
    this.simDetails = simDetails;
    this.personaId = personaId;
    this.personaDetails = personaDetails;
    this.userId = userId;
    this.status = status;
    this.addedOn = addedOn;
    this.config = config;
    this.chatResponse = chatResponse;
  }

  addChats(chats){
    this.chatResponse.push(...chats)
  }
}

function mapJsonToMessageResponse(jsonData) {
  return new MessageResponse(
    jsonData.sender,
    jsonData.content,
    new Date(jsonData.time)
  );
}

function mapJsonToChatResponse(jsonData) {
  const messages = jsonData.messages.map(mapJsonToMessageResponse);
  return new ChatResponse(
    jsonData.public_chat_id,
    jsonData.stage,
    jsonData.status,
    new Date(jsonData.added_on),
    messages, 
    jsonData.stage_history
  );
}

function mapJsonToSimResponse(jsonData) {
  const chatResponses = jsonData.chat_response.map(mapJsonToChatResponse);
  return new SimResponse(
    jsonData.public_sim_id,
    jsonData.sim_details,
    jsonData.persona_id,
    jsonData.persona_details,
    jsonData.user_id,
    jsonData.status,
    jsonData.stage,
    new Date(jsonData.added_on),
    jsonData.config,
    chatResponses
  );
}

export {MessageResponse, ChatResponse, SimResponse, mapJsonToChatResponse, mapJsonToSimResponse, mapJsonToMessageResponse}