import React, { useState, useRef, useEffect } from 'react';

const AudioStreamer = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [waveformData, setWaveformData] = useState([]);
  const audioRef = useRef(null);
  const sourceBufferRef = useRef(null);
  const mediaSourceRef = useRef(null);
  const queueRef = useRef([]);
  const isAppendingRef = useRef(false);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const appendBuffer = async () => {
      if (isAppendingRef.current || queueRef.current.length === 0) return;

      isAppendingRef.current = true;
      const chunk = queueRef.current.shift();
      
      try {
        await new Promise((resolve, reject) => {
          sourceBufferRef.current.appendBuffer(chunk);
          sourceBufferRef.current.onupdateend = resolve;
          sourceBufferRef.current.onerror = reject;
        });
      } catch (error) {
        console.error('Error appending buffer:', error);
      }

      isAppendingRef.current = false;
      appendBuffer();
    };

    const endOfStream = () => {
      if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
        mediaSourceRef.current.endOfStream();
      } else {
        setTimeout(endOfStream, 100); // Retry after 100ms
      }
    };

    const playAudio = async () => {
      if (!mediaSourceRef.current) {
        const response = await fetch(audioUrl);
        const reader = response.body.getReader();

        mediaSourceRef.current = new MediaSource();
        audio.src = URL.createObjectURL(mediaSourceRef.current);

        mediaSourceRef.current.addEventListener('sourceopen', () => {
          sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer('audio/mpeg');

          const pump = async () => {
            const { done, value } = await reader.read();
            if (done) {
              endOfStream();
              return;
            }
            queueRef.current.push(value);
            appendBuffer();
            pump();
          };
          pump();
        });

        if (!audioContextRef.current) {
          audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
          analyserRef.current = audioContextRef.current.createAnalyser();
          const source = audioContextRef.current.createMediaElementSource(audio);
          source.connect(analyserRef.current);
          analyserRef.current.connect(audioContextRef.current.destination);
        }
      }
      audio.play();
    };

    if (isPlaying) {
      playAudio();
    } else {
      audio.pause();
    }

    const updateTimeAndWaveform = () => {
      setCurrentTime(audio.currentTime);
      setDuration(audio.duration || 0);

      if (analyserRef.current) {
        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        analyserRef.current.getByteTimeDomainData(dataArray);
        setWaveformData(Array.from(dataArray));
      }

      animationFrameRef.current = requestAnimationFrame(updateTimeAndWaveform);
    };

    updateTimeAndWaveform();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isPlaying, audioUrl]);

  useEffect(() => {
    return () => {
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close().catch(err => console.error('Error closing AudioContext:', err));
      }
    };
  }, []);

  const togglePlay = () => setIsPlaying(!isPlaying);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <audio ref={audioRef} controls={false} />
      <div className="w-full h-24 bg-gray-200 relative">
        {waveformData.length > 0 && (
          <svg className="w-full h-full" preserveAspectRatio="none" viewBox={`0 0 ${waveformData.length} 255`}>
            <path
              d={waveformData.reduce((path, value, index) => 
                path + (index === 0 ? 'M' : 'L') + `${index} ${value}`, '')}
              stroke="blue"
              strokeWidth="2"
              fill="none"
            />
          </svg>
        )}
      </div>
      <div className="w-full flex justify-between mt-2">
        <span>{formatTime(currentTime)}</span>
        <span>{formatTime(duration)}</span>
      </div>
      <button
        onClick={togglePlay}
        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
        >
        <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z"/></svg>
        {isPlaying ? 'Pause Recording' : 'Play Recording'}
      </button>
    </>
  );
};

export default AudioStreamer;