import { Link } from 'react-router-dom';

const SimulationCard = ({ simulation }) => {
  return (
    <Link
        to={`/sim/${simulation.public_sim_id}`}
        className="bg-white shadow-md rounded-md p-4 transition-all duration-300 hover:shadow-lg hover:-translate-y-1"
    >
        <h2 className="text-lg font-semibold text-gray-800 underline underline-offset-4 decoration-2 decoration-indigo-500">
            {simulation.sim_description}
        </h2>
        <p className="text-gray-600">Public ID: {simulation.public_sim_id}</p>
    </Link>
  );
};

export default SimulationCard