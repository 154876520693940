import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchAuthenticatedUrl, postURL } from "../utils";
import "../assets/styles/Simulation.css";
import { SimulationData, mapJsonToSimResponse } from "../models/Simulation";
import AudioStreamer from "../components/AudioStreamer";

const Transcript = () => {
  const { sim_id, chat_id } = useParams();
  const [simulation, setSimulation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isChatInitialized, setChatInitialized] = useState(false);
  const [resetUrl, setResetUrl] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isSendingReport, setIsSendingReport] = useState(false);
  const streamURL = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${sim_id}/${chat_id}/stream`;

  const handleReport = async () => {
    console.log("in handle report");
    try {
      setIsSendingReport(true);
      await sendReport();
    } catch (error) {
      console.error("Error resetting chat:", error);
    } finally {
      setIsSendingReport(false);
    }
  };

  const sendReport = async function () {
    const reportURL = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${sim_id}/${chat_id}/report`;
    const resp = await postURL(reportURL);
    if (resp.data) {
      alert("Report sent successfully!");
    } else {
      alert("Report sent successfully!");
    }
  };

  const handleReset = async () => {
    try {
      setIsResetting(true);
      await resetChat(resetUrl);
    } catch (error) {
      console.error("Error resetting chat:", error);
    } finally {
      setIsResetting(false);
    }
  };

  const resetChat = async function (url) {
    const resp = await fetchAuthenticatedUrl(url);
    if (resp.data) {
      alert("Reset successful!");
    } else {
      alert("something went wrong!");
    }
  };

  useEffect(() => {
    console.log("in useffect with sim_id and chat_id");
    let url = "";
    if (sim_id && chat_id) {
      url = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${sim_id}/${chat_id}`;
      const resetUrl = url + "/reset";
      setResetUrl(resetUrl);
    } else if (sim_id && !chat_id) {
      url = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${sim_id}`;
    }

    fetchAuthenticatedUrl(url).then((resp) => {
      console.log(resp);
      if (resp.data) {
        setSimulation(mapJsonToSimResponse(resp.data));
        console.log("transcript fetch ---> ", resp.data);
        //TODO handle this
        if (resp.data.status == "CLOSED") {
          setChatInitialized(false);
        } else if (resp.data.status == "ACTIVE") {
          setChatInitialized(true);
        }
      } else {
        setError(resp.error + " " + "check backend service!");
      }
      setIsLoading(false);
    });
  }, [sim_id, chat_id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!simulation) {
    return <div>No simulation found</div>;
  }

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6">        
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Chat Transcript</h2>
          <span className="text-gray-600">
            <b>
              Stage: {simulation.chatResponse[0].stage} &nbsp; Status:{" "}
              {simulation.chatResponse[0].status}
            </b>
          </span>
          {/* Removing Generate Report; TODO: Clean this */}
          {/* <button
            className={`px-4 py-2 rounded-md bg-green-500 text-white ${
              isSendingReport ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleReport}
            disabled={isSendingReport}
          >
            {isSendingReport ? "Sending Report..." : "Generate Report"}
          </button> */}
        </div>
        <div className="space-y-4">
          <div className="w-full">
          <AudioStreamer audioUrl={streamURL} />
          </div>
          {simulation.chatResponse[0].messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.sender === "U" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`max-w-xs rounded-lg p-4 ${
                  message.sender === "U"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-gray-800"
                }`}
              >
                <p>{message.content}</p>
                <p className="text-xs mt-1">{message.time.toLocaleString()}</p>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="mt-6">
              <h3 className="text-lg font-semibold mb-2">Stage Evaluation</h3>
              <ul className="list-disc pl-4">
                {Object.entries(simulation.chatResponse[0].stageEval).map(([key, value]) => (
                  <li key={key}>
                    <span className="font-semibold">{key}: </span>
                    <span>{value}</span>
                  </li>
                ))}
              </ul>
            </div> */}
        <div className="mt-6 flex justify-end">
          {/* <button
            className={`px-4 py-2 rounded-md bg-red-500 text-white ${
              isResetting ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleReset}
            disabled={isResetting}
          >
            {isResetting ? 'Resetting...' : 'Reset Chat'}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Transcript;
