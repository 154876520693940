import React, { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { postURL } from '../utils';
import { X } from 'lucide-react';


const CloseIcon = ({ chat, onClose }) => {
  const { sim_id, chat_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = async () => {
    const confirmed = window.confirm('Are you sure you want to close this chat?');
    if (!confirmed) return;

    setIsLoading(true);
    try {
      // Make an API call to close the chat
      const url = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${sim_id}/${chat_id}/end`;
      const response = await postURL(url)
      if(response.data){
        navigate(`/sim/${sim_id}/${chat_id}/transcript`);
      }
    } catch (error) {
      console.error('Error closing chat:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className={`
        flex items-center justify-center
        px-3 py-2
        text-sm font-medium
        text-white bg-red-500
        rounded-md shadow-sm
        transition-all duration-200
        hover:bg-red-600 hover:shadow-md
        focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50
        disabled:opacity-50 disabled:cursor-not-allowed
        ${isLoading ? 'animate-pulse' : ''}
      `}
      onClick={handleClose}
      disabled={isLoading}
    >
      <X className="w-4 h-4 mr-2" />
      {isLoading ? 'Closing...' : 'End Chat'}
    </button>
  );
};

const StatusTag = ({ status }) => {
    const className = `px-2 py-1 rounded-full font-semibold text-xs ${
      status === 'ACTIVE'
        ? 'bg-green-200 text-green-800'
        : 'bg-red-200 text-red-800'
    }`;
    return <span className={className}>{status}</span>;
  };

const ChatClose = ({ chat }) => {
  return (
    <div className="flex items-center justify-between">
      {/* <p><StatusTag status={chat.status} /></p> */}
      <CloseIcon chat={chat} />
    </div>
  );
};

export default ChatClose;