import React, { useEffect, useRef } from 'react';

const ChatHistory = ({messages}) => { 
  
//   const appendRandomMessages = () => {
//     appendMessage('user', 'Hello there!');
//     appendMessage('bot', 'Hi! How can I assist you today?');
//     appendMessage('user', 'I need help with my order.');
//     appendMessage('bot', 'Sure, please provide your order number.');
//   };



  return (
    <div className="grid space-y-4 m-2 flex-grow" >
      {messages.length === 0 ? (
        <>
        <div className="h-[50vh]">  </div>
        <div className="mx-auto p-4 rounded-lg bg-gray-200">
            <p className="text-sm text-center">There are no messages in this chat.</p>
        </div>

        </>
      ) : (
        messages.map((message, index) => (
            <div
                key={index}
                className={ `p-4 rounded-lg max-w-[70%] ${
                    message.sender === 'U' ? 'justify-self-end bg-gray-200' : 'justify-self-start bg-indigo-200'
                }`}
            >
          {/* {console.log(message)} */}
            <p className="text-sm p-1">{message.content}</p>
            <div className="flex justify-end"><p className="text-xs">{message.time.toLocaleDateString()}</p></div>
            </div>
        ))
        )}
    </div>
  );
}

export default ChatHistory;
