import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import SimulationCard from './SimulationCard';
import { fetchAuthenticatedUrl } from '../utils';

const SimulationGrid = () => {
  const [simulations, setSimulations] = useState([]);
  const navigate = useNavigate()
  
  function listToMap(list, keyProp) {
    return list.reduce((map, item) => {
      map[item[keyProp]] = item;
      return map;
    }, {});
  }


  useEffect(() => {
    const url = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/all`;
    console.log("fetching from...", url)
    fetchAuthenticatedUrl(url).then((resp) => {
         if(resp.data){
          const configURL = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/config`;
          fetchAuthenticatedUrl(configURL).then((configResp) => {
              const configs = listToMap(configResp.data, 'id')              
              const mergedData = resp.data.map((obj, i) => ({...obj, sim_description: configs[obj.sim_config_id].description}))
              setSimulations(mergedData)
            })
         } else {
           navigate("/login")
         }
        })
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:px-8 lg:px-16">
      {simulations.map(simulation => (
        <SimulationCard key={simulation.public_sim_id} simulation={simulation} />
      ))}
    </div>
  );
};

export default SimulationGrid